import React from "react"
import Thanks from "../components/Thanks"
import { Layout } from "../components/Layout"

const ThanksPage = () => {
  return (
    <Layout>
      <Thanks />
    </Layout>
  )
}

export default ThanksPage
